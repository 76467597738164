export function YoutubeVideo({
  url,
  useAspectRatio,
}: {
  url: string;
  useAspectRatio?: boolean;
}) {
  const urlEmbed = !url.match('/embed')
    ? url.includes('youtu.be/')
      ? 'https://www.youtube.com/embed/' + url.replace('https://youtu.be/', '')
      : 'https://www.youtube.com/embed/' +
        url.replace('https://www.youtube.com/watch?v=', '')
    : url;

  return (
    <div
      className={useAspectRatio ? 'aspect-h-9 aspect-w-16' : 'h-full w-full'}
    >
      <iframe
        className='yt_player_iframe h-full w-full'
        src={urlEmbed}
        loading='lazy'
        frameBorder='0'
        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
        allowFullScreen
      />
    </div>
  );
}
