const pathTranslations = {
  '/buy': {
    'fr-FR': '/acheter',
    'de-DE': '/kaufen',
    'es-ES': '/comprar',
    'cs-CZ': '/koupit',
    'pl-PL': '/kup',
    'it-IT': '/comprare',
    'en-GB': '/buy',
    'de-AT': '/kaufen',
  },
  '/buy/[slug]': {
    'fr-FR': '/acheter/[slug]',
    'de-DE': '/kaufen/[slug]',
    'es-ES': '/comprar/[slug]',
    'pl-PL': '/kup/[slug]',
    'it-IT': '/comprare/[slug]',
    'cs-CZ': '/koupit/[slug]',
    'en-GB': '/buy/[slug]',
    'de-AT': '/kaufen/[slug]',
  },
  '/sell': {
    'fr-FR': '/vendre',
    'de-DE': '/verkaufen',
    'es-ES': '/vender',
    'pl-PL': '/sprzedac',
    'it-IT': '/vendere',
    'cs-CZ': '/prodavat',
    'en-GB': '/sell',
    'de-AT': '/verkaufen',
  },
  '/company': {
    'fr-FR': '/entreprise',
    'de-DE': '/unternehmen',
    'es-ES': '/empresa',
    'pl-PL': '/firma',
    'it-IT': '/azienda',
    'cs-CZ': '/spolecnost',
    'en-GB': '/company',
    'de-AT': '/unternehmen',
  },
  '/jobs': {
    'fr-FR': '/emplois',
    'de-DE': '/jobs',
    'es-ES': '/trabajos',
    'pl-PL': '/miejsca-pracy',
    'it-IT': '/lavori',
    'cs-CZ': '/pracovni-mista',
    'en-GB': '/jobs',
    'de-AT': '/jobs',
  },
  '/press': {
    'fr-FR': '/presse',
    'de-DE': '/presse',
    'es-ES': '/prensa',
    'pl-PL': '/prasa',
    'it-IT': '/stampa',
    'cs-CZ': '/tisk',
    'en-GB': '/press',
    'de-AT': '/presse',
  },
  '/now-with-bevmaq': {
    'fr-FR': '/maintenant-avec-bevmaq',
    'de-DE': '/jetzt-mit-bevmaq',
    'es-ES': '/ahora-con-bevmaq',
    'pl-PL': '/teraz-z-bevmaq',
    'it-IT': '/ora-con-bevmaq',
    'cs-CZ': '/nyni-s-bevmaq',
    'en-GB': '/now-with-bevmaq',
    'de-AT': '/jetzt-mit-bevmaq',
  },
  '/contact': {
    'fr-FR': '/contact',
    'de-DE': '/kontakt',
    'es-ES': '/contacto',
    'pl-PL': '/kontakt',
    'it-IT': '/contatto',
    'cs-CZ': '/kontakt',
    'en-GB': '/contact',
    'de-AT': '/kontakt',
  },
  '/services/warehouse-service': {
    'fr-FR': '/services/service-d-entreposage',
    'de-DE': '/dienstleistungen/lagerservice',
    'es-ES': '/servicio/servicio-de-almacen',
    'pl-PL': '/uslugi/obsluga-magazynu',
    'it-IT': '/servizi/servizio-di-magazzino',
    'cs-CZ': '/sluzby/skladove-sluzby',
    'en-GB': '/services/warehouse-service',
    'de-AT': '/dienstleistungen/lagerservice',
  },
  '/services/project-consult': {
    'fr-FR': '/services/sproject-consult',
    'de-DE': '/dienstleistungen/projekt-beratung',
    'es-ES': '/servicio/project-consult',
    'pl-PL': '/uslugi/project-consult',
    'it-IT': '/servizi/project-consult',
    'cs-CZ': '/sluzby/project-consult',
    'en-GB': '/services/project-consult',
    'de-AT': '/dienstleistungen/projekt-beratung',
  },
  '/services/data-driven-pricing': {
    'fr-FR': '/services/analyse-des-prix',
    'de-DE': '/dienstleistungen/datengesteuerte-preisgestaltung',
    'es-ES': '/servicios/analisis-precios',
    'pl-PL': '/uslugi/analiza-cen',
    'it-IT': '/servizi/prezzi-basati-sui-dati',
    'cs-CZ': '/sluzby/cenotvorba-zalozena-na-datech',
    'en-GB': '/services/data-driven-pricing',
    'de-AT': '/dienstleistungen/datengesteuerte-preisgestaltung',
  },
  '/services/global-marketing': {
    'fr-FR': '/services/marketing-global',
    'de-DE': '/dienstleistungen/global-marketing',
    'es-ES': '/servicios/global-marketing',
    'pl-PL': '/uslugi/marketing-globalny',
    'it-IT': '/servizi/marketing-globale',
    'cs-CZ': '/sluzby/globalni-marketing',
    'en-GB': '/services/global-marketing',
    'de-AT': '/dienstleistungen/global-marketing',
  },
  '/services/international-logistics': {
    'fr-FR': '/services/logistique-internationale',
    'de-DE': '/dienstleistungen/internationale-logistik',
    'es-ES': '/servicios/logistica-internacional',
    'pl-PL': '/uslugi/logistyka-miedzynarodowa',
    'it-IT': '/servizi/logistica-internazionale',
    'cs-CZ': '/sluzby/mezinarodni-logistika',
    'en-GB': '/services/international-logistics',
    'de-AT': '/dienstleistungen/internationale-logistik',
  },
  '/imprint': {
    'fr-FR': '/mentions-legales',
    'de-DE': '/impressum',
    'es-ES': '/aviso-legal',
    'pl-PL': '/imprint',
    'it-IT': '/impronta',
    'cs-CZ': '/otisk',
    'en-GB': '/imprint',
    'de-AT': '/impressum',
  },
  '/privacy': {
    'fr-FR': '/confidentialite',
    'de-DE': '/datenschutz',
    'es-ES': '/privacidad',
    'pl-PL': '/prywatnosc',
    'it-IT': '/privacy',
    'cs-CZ': '/soukromí',
    'en-GB': '/privacy',
    'de-AT': '/datenschutz',
  },
  '/terms-and-conditions': {
    'fr-FR': '/termes-et-conditions',
    'de-DE': '/allgemeine-geschaeftsbedingungen',
    'es-ES': '/terminos-y-condiciones',
    'pl-PL': '/regulamin',
    'it-IT': '/termini-e-condizioni',
    'cs-CZ': '/podmínky',
    'en-GB': '/terms-and-conditions',
    'de-AT': '/allgemeine-geschaeftsbedingungen',
  },
  '/buy/industry-category/[industry_category_slug]': {
    'fr-FR': '/acheter/categorie-industrie/[industry_category_slug]',
    'de-DE': '/kaufen/branchenkategorie/[industry_category_slug]',
    'es-ES': '/comprar/categoria-industrial/[industry_category_slug]',
    'pl-PL': '/kup/kategoria-branzowa/[industry_category_slug]',
    'it-IT': '/comprare/categoria-industriale/[industry_category_slug]',
    'cs-CZ': '/koupit/kategorie-odvetvi/[industry_category_slug]',
    'en-GB': '/buy/industry-category/[industry_category_slug]',
    'de-AT': '/kaufen/branchenkategorie/[industry_category_slug]',
  },
  '/buy/industry-category/food': {
    'fr-FR': '/acheter/categorie-industrie/nourriture',
    'de-DE': '/kaufen/branchenkategorie/lebensmittel',
    'es-ES': '/comprar/categoria-industrial/comida',
    'pl-PL': '/kup/kategoria-branzowa/jedzenie',
    'it-IT': '/comprare/categoria-industriale/cibo',
    'cs-CZ': '/koupit/kategorie-odvetvi/potraviny',
    'en-GB': '/buy/industry-category/food',
    'de-AT': '/kaufen/branchenkategorie/lebensmittel',
  },
  '/buy/industry-category/beverage': {
    'fr-FR': '/acheter/categorie-industrie/boisson',
    'de-DE': '/kaufen/branchenkategorie/getranke',
    'es-ES': '/comprar/categoria-industrial/bebida',
    'pl-PL': '/kup/kategoria-branzowa/napoj',
    'it-IT': '/comprare/categoria-industriale/bevanda',
    'cs-CZ': '/koupit/kategorie-odvetvi/napoje',
    'en-GB': '/buy/industry-category/beverage',
    'de-AT': '/kaufen/branchenkategorie/getranke',
  },
  '/buy/category/[category_slug]': {
    'fr-FR': '/acheter/categorie/[category_slug]',
    'de-DE': '/kaufen/kategorie/[category_slug]',
    'es-ES': '/comprar/categoria/[category_slug]',
    'pl-PL': '/kup/kategoria/[category_slug]',
    'it-IT': '/comprare/categoria/[category_slug]',
    'cs-CZ': '/koupit/kategorie/[category_slug]',
    'en-GB': '/buy/category/[category_slug]',
    'de-AT': '/kaufen/kategorie/[category_slug]',
  },
  '/buy/category/palletizing': {
    'fr-FR': '/acheter/categorie/palettisation',
    'de-DE': '/kaufen/kategorie/palettieren',
    'es-ES': '/comprar/categoria/paletizacion',
    'pl-PL': '/kup/kategoria/paletyzacja',
    'it-IT': '/comprare/categoria/palettizzazione',
    'cs-CZ': '/koupit/kategorie/paletizace',
    'en-GB': '/buy/category/palletizing',
    'de-AT': '/kaufen/kategorie/palettieren',
  },
  '/buy/category/packaging': {
    'fr-FR': '/acheter/categorie/encaissage',
    'de-DE': '/kaufen/kategorie/verpackung',
    'es-ES': '/comprar/categoria/embalaje',
    'pl-PL': '/kup/kategoria/pakowanie',
    'it-IT': '/comprare/categoria/imballaggio',
    'cs-CZ': '/koupit/kategorie/baleni',
    'en-GB': '/buy/category/packaging',
    'de-AT': '/kaufen/kategorie/verpackung',
  },
  '/buy/category/washing': {
    'fr-FR': '/acheter/categorie/lavage',
    'de-DE': '/kaufen/kategorie/reinigen',
    'es-ES': '/comprar/categoria/lavado',
    'pl-PL': '/kup/kategoria/mycie',
    'it-IT': '/comprare/categoria/lavaggio',
    'cs-CZ': '/koupit/kategorie/prani',
    'en-GB': '/buy/category/washing',
    'de-AT': '/kaufen/kategorie/reinigen',
  },
  '/buy/category/blowmoulding-machines': {
    'fr-FR': '/acheter/categorie/souffleuse',
    'de-DE': '/kaufen/kategorie/blasmaschine',
    'es-ES': '/comprar/categoria/soplado',
    'pl-PL': '/kup/kategoria/formowanie-rozdmuchowe',
    'it-IT': '/comprare/categoria/tecnilogia-di-soffiaggio',
    'cs-CZ': '/koupit/kategorie/vyfukovaci-stroje',
    'en-GB': '/buy/category/blowmoulding-machines',
    'de-AT': '/kaufen/kategorie/blasmaschine',
  },
  '/buy/category/rinsing': {
    'fr-FR': '/acheter/categorie/rinceuse',
    'de-DE': '/kaufen/kategorie/rinser',
    'es-ES': '/comprar/categoria/enjuagado',
    'pl-PL': '/kup/kategoria/plukanie',
    'it-IT': '/comprare/categoria/risciacquo',
    'cs-CZ': '/koupit/kategorie/oplachovani',
    'en-GB': '/buy/category/rinsing',
    'de-AT': '/kaufen/kategorie/rinser',
  },
  '/buy/category/filling': {
    'fr-FR': '/acheter/categorie/remplissage',
    'de-DE': '/kaufen/kategorie/abfullen',
    'es-ES': '/comprar/categoria/llenado',
    'pl-PL': '/kup/kategoria/rozlewanie',
    'it-IT': '/comprare/categoria/riempimento',
    'cs-CZ': '/koupit/kategorie/plneni',
    'en-GB': '/buy/category/filling',
    'de-AT': '/kaufen/kategorie/abfullen',
  },
  '/buy/category/tunnel-pasteurizer': {
    'fr-FR': '/acheter/categorie/pasteurisateur',
    'de-DE': '/kaufen/kategorie/tunnelpasteur',
    'es-ES': '/comprar/categoria/pasterizado',
    'pl-PL': '/kup/kategoria/pasteryzacja',
    'it-IT': '/comprare/categoria/pastorizzatore',
    'cs-CZ': '/koupit/kategorie/tunelovy-pasterizator',
    'en-GB': '/buy/category/tunnel-pasteurizer',
    'de-AT': '/kaufen/kategorie/tunnelpasteur',
  },
  '/buy/category/labelling': {
    'fr-FR': '/acheter/categorie/etiqueter',
    'de-DE': '/kaufen/kategorie/etikettieren',
    'es-ES': '/comprar/categoria/etiquetado',
    'pl-PL': '/kup/kategoria/etykietowanie',
    'it-IT': '/comprare/categoria/etichettatura',
    'cs-CZ': '/koupit/kategorie/oznacovani',
    'en-GB': '/buy/category/labelling',
    'de-AT': '/kaufen/kategorie/etikettieren',
  },
  '/buy/category/process-technology': {
    'fr-FR': '/acheter/categorie/technologie-de-processus',
    'de-DE': '/kaufen/kategorie/prozesstechnik',
    'es-ES': '/comprar/categoria/proceso',
    'pl-PL': '/kup/kategoria/procesy-technologiczne',
    'it-IT': '/comprare/categoria/tecnologia-di-processo',
    'cs-CZ': '/koupit/kategorie/procesne-technologicke',
    'en-GB': '/buy/category/process-technology',
    'de-AT': '/kaufen/kategorie/prozesstechnik',
  },
  '/buy/category/complete-filling-lines': {
    'fr-FR': '/acheter/categorie/lignes-dembouteillage-completes',
    'de-DE': '/kaufen/kategorie/komplette-abfulllinien',
    'es-ES': '/comprar/categoria/lineas-completas',
    'pl-PL': '/kup/kategoria/kompletne-linie-rozlewnicze',
    'it-IT': '/comprare/categoria/linea-di-riempimento-completa',
    'cs-CZ': '/koupit/kategorie/kompletni-vyplneni-radku',
    'en-GB': '/buy/category/complete-filling-lines',
    'de-AT': '/kaufen/kategorie/komplette-abfulllinien',
  },
  '/buy/category/capping-decapping': {
    'fr-FR': '/acheter/categorie/capsulage',
    'de-DE': '/kaufen/kategorie/verschlieen-abschrauben',
    'es-ES': '/comprar/categoria/taponado-destaponado',
    'pl-PL': '/kup/kategoria/zamykanie-otwieranie',
    'it-IT': '/comprare/categoria/capsulatura-decapsulatura',
    'cs-CZ': '/koupit/kategorie/uzavirani-a-uzavirani',
    'en-GB': '/buy/category/capping-decapping',
    'de-AT': '/kaufen/kategorie/verschlieen-abschrauben',
  },
  '/buy/category/inspection': {
    'fr-FR': '/acheter/categorie/inspection',
    'de-DE': '/kaufen/kategorie/inspektionstechnik',
    'es-ES': '/comprar/categoria/inspeccion',
    'pl-PL': '/kup/kategoria/inspekcja',
    'it-IT': '/comprare/categoria/ispezione',
    'cs-CZ': '/koupit/kategorie/inspekce',
    'en-GB': '/buy/category/inspection',
    'de-AT': '/kaufen/kategorie/inspektionstechnik',
  },
  '/buy/category/keg-technology': {
    'fr-FR': '/acheter/categorie/technologie-de-la-bouteille',
    'de-DE': '/kaufen/kategorie/keg-technik',
    'es-ES': '/comprar/categoria/maquinas-de-barril',
    'pl-PL': '/kup/kategoria/technologia-keg',
    'it-IT': '/comprare/categoria/tecnologia-dei-fusti',
    'cs-CZ': '/koupit/kategorie/keg-technologie',
    'en-GB': '/buy/category/keg-technology',
    'de-AT': '/kaufen/kategorie/keg-technik',
  },
  '/buy/category/carton-packing': {
    'fr-FR': '/acheter/categorie/emballage-en-carton',
    'de-DE': '/kaufen/kategorie/karton-verpackung',
    'es-ES': '/comprar/categoria/maquinas-de-carton',
    'pl-PL': '/kup/kategoria/pakowanie-kartonow',
    'it-IT': '/comprare/categoria/imballaggio-in-cartone',
    'cs-CZ': '/koupit/kategorie/baleni-do-kartonu',
    'en-GB': '/buy/category/carton-packing',
    'de-AT': '/kaufen/kategorie/karton-verpackung',
  },
  '/buy/category/brewing-fermenting': {
    'fr-FR': '/acheter/categorie/brassage-et-fermentation',
    'de-DE': '/kaufen/kategorie/brauen-fermentieren',
    'es-ES': '/comprar/categoria/elaborar-cerveza-y-fermentar',
    'pl-PL': '/kup/kategoria/warzenie-piwa-i-fermentacja',
    'it-IT': '/comprare/categoria/birra-e-fermentazione',
    'cs-CZ': '/koupit/kategorie/vareni-piva-a-kvaseni',
    'en-GB': '/buy/category/brewing-fermenting',
    'de-AT': '/kaufen/kategorie/brauen-fermentieren',
  },
  '/buy/category/other-machinery': {
    'fr-FR': '/acheter/categorie/autres-machines',
    'de-DE': '/kaufen/kategorie/andere-maschinen',
    'es-ES': '/comprar/categoria/otras-maquinas',
    'pl-PL': '/kup/kategoria/inne-maszyny',
    'it-IT': '/comprare/categoria/altra-macchina',
    'cs-CZ': '/koupit/kategorie/ostatni-stroje',
    'en-GB': '/buy/category/other-machinery',
    'de-AT': '/kaufen/kategorie/andere-maschinen',
  },
  '/buy/category/bakery-pastry-equipment': {
    'fr-FR': '/acheter/categorie/equipement-de-boulangerie-et-de-patisserie',
    'de-DE': '/kaufen/kategorie/backerei-konditoreiausrustung',
    'es-ES': '/comprar/categoria/equipos-de-panaderia-y-pasteleria',
    'pl-PL': '/kup/kategoria/sprzet-piekarniczy-i-cukierniczy',
    'it-IT': '/comprare/categoria/attrezzature-per-panetteria-e-pasticceria',
    'cs-CZ': '/koupit/kategorie/pekarske-a-cukrarske-vybaveni',
    'en-GB': '/buy/category/bakery-pastry-equipment',
    'de-AT': '/kaufen/kategorie/backerei-konditoreiausrustung',
  },
  '/buy/category/packaging-machinery': {
    'fr-FR': '/acheter/categorie/machines-demballage',
    'de-DE': '/kaufen/kategorie/verpackungsmaschinen',
    'es-ES': '/comprar/categoria/maquinas-de-embalaje',
    'pl-PL': '/kup/kategoria/maszyny-pakujace',
    'it-IT': '/comprare/categoria/macchine-per-limballaggio',
    'cs-CZ': '/koupit/kategorie/baleni-stroje',
    'en-GB': '/buy/category/packaging-machinery',
    'de-AT': '/kaufen/kategorie/verpackungsmaschinen',
  },
  '/buy/category/grain-processing-machines': {
    'fr-FR': '/acheter/categorie/machines-de-transformation-des-cereales',
    'de-DE': '/kaufen/kategorie/getreideverarbeitungsmaschinen',
    'es-ES': '/comprar/categoria/maquinas-de-procesamiento-de-granos',
    'pl-PL': '/kup/kategoria/maszyny-do-przetwarzania-zboza',
    'it-IT': '/comprare/categoria/macchine-per-la-lavorazione-dei-cereali',
    'cs-CZ': '/koupit/kategorie/stroje-na-zpracovani-obili',
    'en-GB': '/buy/category/grain-processing-machines',
    'de-AT': '/kaufen/kategorie/getreideverarbeitungsmaschinen',
  },
  '/buy/category/mixing-machinery': {
    'fr-FR': '/acheter/categorie/machines-de-melange',
    'de-DE': '/kaufen/kategorie/mischmaschinen',
    'es-ES': '/comprar/categoria/maquinas-de-mezcla',
    'pl-PL': '/kup/kategoria/maszyny-do-mieszania',
    'it-IT': '/comprare/categoria/macchine-per-mescolare',
    'cs-CZ': '/koupit/kategorie/michaci-stroje',
    'en-GB': '/buy/category/mixing-machinery',
    'de-AT': '/kaufen/kategorie/mischmaschinen',
  },
  '/buy/category/fat-production-handling': {
    'fr-FR': '/acheter/categorie/production-et-manipulation-de-graisses',
    'de-DE': '/kaufen/kategorie/fettproduktion-handhabung',
    'es-ES': '/comprar/categoria/produccion-y-manejo-de-grasas',
    'pl-PL': '/kup/kategoria/produkcja-i-obsuga-tuszczow',
    'it-IT': '/comprare/categoria/produzione-e-gestione-dei-grassi',
    'cs-CZ': '/koupit/kategorie/produkce-a-manipulace-s-tuky',
    'en-GB': '/buy/category/fat-production-handling',
    'de-AT': '/kaufen/kategorie/fettproduktion-handhabung',
  },
  '/buy/category/fruit-vegetable-processing': {
    'fr-FR': '/acheter/categorie/transformation-des-fruits-et-legumes',
    'de-DE': '/kaufen/kategorie/obst-und-gemuseverarbeitung',
    'es-ES': '/comprar/categoria/procesamiento-de-frutas-y-verduras',
    'pl-PL': '/kup/kategoria/przetworstwo-owocow-i-warzyw',
    'it-IT': '/comprare/categoria/lavorazione-di-frutta-e-verdura',
    'cs-CZ': '/koupit/kategorie/zpracovani-ovoce-a-zeleniny',
    'en-GB': '/buy/category/fruit-vegetable-processing',
    'de-AT': '/kaufen/kategorie/obst-und-gemuseverarbeitung',
  },
  '/buy/category/cooking-vessels': {
    'fr-FR': '/acheter/categorie/ustensiles-de-cuisson',
    'de-DE': '/kaufen/kategorie/kochgerate',
    'es-ES': '/comprar/categoria/utensilios-de-cocina',
    'pl-PL': '/kup/kategoria/naczynia-do-gotowania',
    'it-IT': '/comprare/categoria/utensili-da-cucina',
    'cs-CZ': '/koupit/kategorie/varne-nadoby',
    'en-GB': '/buy/category/cooking-vessels',
    'de-AT': '/kaufen/kategorie/kochgerate',
  },
  '/buy/category/confectionery-production': {
    'fr-FR': '/acheter/categorie/production-de-confiserie',
    'de-DE': '/kaufen/kategorie/suwarenproduktion',
    'es-ES': '/comprar/categoria/produccion-de-confiteria',
    'pl-PL': '/kup/kategoria/produkcja-sodyczy',
    'it-IT': '/comprare/categoria/produzione-di-dolciumi',
    'cs-CZ': '/koupit/kategorie/vyroba-cukrovinek',
    'en-GB': '/buy/category/confectionery-production',
    'de-AT': '/kaufen/kategorie/suwarenproduktion',
  },
  '/buy/category/meat-processing': {
    'fr-FR': '/acheter/categorie/transformation-de-la-viande',
    'de-DE': '/kaufen/kategorie/fleischverarbeitung',
    'es-ES': '/comprar/categoria/procesamiento-de-carne',
    'pl-PL': '/kup/kategoria/przetworstwo-miesa',
    'it-IT': '/comprare/categoria/lavorazione-della-carne',
    'cs-CZ': '/koupit/kategorie/zpracovani-masa',
    'en-GB': '/buy/category/meat-processing',
    'de-AT': '/kaufen/kategorie/fleischverarbeitung',
  },
  '/buy/category/fish-processing': {
    'fr-FR': '/acheter/categorie/transformation-du-poisson',
    'de-DE': '/kaufen/kategorie/fischverarbeitung',
    'es-ES': '/comprar/categoria/procesamiento-de-pescado',
    'pl-PL': '/kup/kategoria/przetworstwo-ryb',
    'it-IT': '/comprare/categoria/lavorazione-del-pesce',
    'cs-CZ': '/koupit/kategorie/zpracovani-ryb',
    'en-GB': '/buy/category/fish-processing',
    'de-AT': '/kaufen/kategorie/fischverarbeitung',
  },
  '/buy/category/dairy-plant-equipment': {
    'fr-FR': '/acheter/categorie/equipements-laitiers',
    'de-DE': '/kaufen/kategorie/molkereianlagen',
    'es-ES': '/comprar/categoria/equipos-para-plantas-lacteas',
    'pl-PL': '/kup/kategoria/wyposazenie-mleczarni',
    'it-IT': '/comprare/categoria/attrezzature-per-caseifici',
    'cs-CZ': '/koupit/kategorie/zarizeni-mlekarny',
    'en-GB': '/buy/category/dairy-plant-equipment',
    'de-AT': '/kaufen/kategorie/molkereianlagen',
  },
  '/buy/category/separators': {
    'fr-FR': '/acheter/categorie/separateurs',
    'de-DE': '/kaufen/kategorie/separatoren',
    'es-ES': '/comprar/categoria/separadores',
    'pl-PL': '/kup/kategoria/separatory',
    'it-IT': '/comprare/categoria/separatori',
    'cs-CZ': '/koupit/kategorie/separatory',
    'en-GB': '/buy/category/separators',
    'de-AT': '/kaufen/kategorie/separatoren',
  },
  '/buy/category/decanters': {
    'fr-FR': '/acheter/categorie/decanteurs',
    'de-DE': '/kaufen/kategorie/dekanter',
    'es-ES': '/comprar/categoria/decantadores',
    'pl-PL': '/kup/kategoria/dekantery',
    'it-IT': '/comprare/categoria/decanter',
    'cs-CZ': '/koupit/kategorie/dekantery',
    'en-GB': '/buy/category/decanters',
    'de-AT': '/kaufen/kategorie/dekanter',
  },
  '/buy/category/delicatessen-machinery': {
    'fr-FR': '/acheter/categorie/machines-de-charcuterie',
    'de-DE': '/kaufen/kategorie/feinkostmaschinen',
    'es-ES': '/comprar/categoria/maquinas-para-delicatessen',
    'pl-PL': '/kup/kategoria/maszyny-delikatesowe',
    'it-IT': '/comprare/categoria/macchine-per-gastronomia',
    'cs-CZ': '/koupit/kategorie/lahudkarske-stroje',
    'en-GB': '/buy/category/delicatessen-machinery',
    'de-AT': '/kaufen/kategorie/feinkostmaschinen',
  },
  '/buy/category/food-smoking-equipment': {
    'fr-FR': '/acheter/categorie/equipements-de-fumage',
    'de-DE': '/kaufen/kategorie/raucheranlagen',
    'es-ES': '/comprar/categoria/equipos-para-ahumar-alimentos',
    'pl-PL': '/kup/kategoria/sprzet-do-wedzenia-zywnosci',
    'it-IT': '/comprare/categoria/attrezzature-per-affumicare-gli-alimenti',
    'cs-CZ': '/koupit/kategorie/udici-zarizeni',
    'en-GB': '/buy/category/food-smoking-equipment',
    'de-AT': '/kaufen/kategorie/raucheranlagen',
  },
  '/buy/category/laboratory-equipment-for-food': {
    'fr-FR': '/acheter/categorie/equipement-de-laboratoire-pour-aliments',
    'de-DE': '/kaufen/kategorie/laborausrustung-fur-lebensmittel',
    'es-ES': '/comprar/categoria/equipos-de-laboratorio-para-alimentos',
    'pl-PL': '/kup/kategoria/sprzet-laboratoryjny-do-zywnosci',
    'it-IT': '/comprare/categoria/apparecchiature-da-laboratorio-per-alimenti',
    'cs-CZ': '/koupit/kategorie/laboratorni-vybaveni-pro-potraviny',
    'en-GB': '/buy/category/laboratory-equipment-for-food',
    'de-AT': '/kaufen/kategorie/laborausrustung-fur-lebensmittel',
  },
  '/buy/category/dryer': {
    'fr-FR': '/acheter/categorie/sechoir',
    'de-DE': '/kaufen/kategorie/trockner',
    'es-ES': '/comprar/categoria/secador',
    'pl-PL': '/kup/kategoria/suszarka',
    'it-IT': '/comprare/categoria/essiccatore',
    'cs-CZ': '/koupit/kategorie/susicka',
    'en-GB': '/buy/category/dryer',
    'de-AT': '/kaufen/kategorie/trockner',
  },
  '/buy/category/stirring-machines-bakery-mixers': {
    'fr-FR':
      '/acheter/categorie/machines-a-melanger-et-melangeurs-de-boulangerie',
    'de-DE': '/kaufen/kategorie/ruhrmaschinen-backereimischer',
    'es-ES':
      '/comprar/categoria/maquinas-de-agitacion-y-mezcladoras-de-panaderia',
    'pl-PL': '/kup/kategoria/maszyny-mieszajace-i-miksery-piekarnicze',
    'it-IT':
      '/comprare/categoria/macchine-per-mescolare-e-impastatrici-da-forno',
    'cs-CZ': '/koupit/kategorie/michaci-stroje-a-pekarske-mixery',
    'en-GB': '/buy/category/stirring-machines-bakery-mixers',
    'de-AT': '/kaufen/kategorie/ruhrmaschinen-backereimischer',
  },
  '/': {
    'fr-FR': '/',
    'de-DE': '/',
    'es-ES': '/',
    'pl-PL': '/',
    'it-IT': '/',
    'cs-CZ': '/',
    'en-GB': '/',
    'de-AT': '/',
  },
};

exports.pathTranslations = pathTranslations;
