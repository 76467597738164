import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { AppState } from '@/app/store';
import { getQueryStringValue } from '@/lib/hooks/useQueryString/queryString';
import {
  Category,
  Country,
  IndustryCategory,
  Manufacturer,
  Product,
} from '@/lib/types/bevmaq.types';
import qs from 'query-string';
import { isEqual, omitBy } from 'lodash';
import Router from 'next/router';
export type Iorder_by = 'created' | 'price' | 'year';
export type Iorder = 'desc' | 'asc';
export type ILocale =
  | 'de-DE'
  | 'en-US'
  | 'fr-FR'
  | 'es-ES'
  | 'pl-PL'
  | 'it-IT'
  | 'cs-CZ'
  | 'en-GB'
  | 'de-AT'
  | undefined;

export interface ProductFiltersI {
  page: number;
  minPrice: number | null;
  maxPrice: number | null;
  minYear: number | null;
  maxYear: number | null;
  selectedCategory?: string[];
  selectedIndustryCategory?: string[];
  manufacturers: string[] | null;
  countries: string[] | null;
  search: string;
  order_by: Iorder_by;
  order: Iorder;
  locale: ILocale;
}
export interface ProductFilterState extends ProductFiltersI {
  // Api data:
  categoriesOptions: Category[];
  industryCategoriesOptions: IndustryCategory[];
  manufacturersOptions: Manufacturer[];
  countriesOptions: Country[];
  products: Product[];
  isLoading: boolean;
  count: number;
}

export const initialState: ProductFilterState = {
  page: 1,
  minPrice: null,
  maxPrice: null,
  minYear: null,
  maxYear: null,
  selectedCategory: [],
  selectedIndustryCategory: [],
  categoriesOptions: [],
  industryCategoriesOptions: [],
  manufacturers: [],
  manufacturersOptions: [],
  countries: [],
  countriesOptions: [],
  products: [],
  isLoading: false,
  search: '',
  locale: undefined,
  count: 0,
  order_by: 'created',
  order: 'desc',
};

export const productFilterSlice = createSlice({
  name: 'productFilter',
  initialState,
  reducers: {
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setSelectedCategory: (state, action: PayloadAction<string[]>) => {
      state.selectedCategory = action.payload.map((e) => e.toLowerCase());
    },
    setCategoriesOptions: (state, action: PayloadAction<Category[]>) => {
      state.categoriesOptions = action.payload;
    },
    setIndustryCategoriesOptions: (
      state,
      action: PayloadAction<IndustryCategory[]>
    ) => {
      state.industryCategoriesOptions = action.payload;
    },
    setMinPrice: (state, action: PayloadAction<number | null>) => {
      state.minPrice = action.payload;
    },
    setMaxPrice: (state, action: PayloadAction<number | null>) => {
      state.maxPrice = action.payload;
    },
    setCountries: (state, action: PayloadAction<string[]>) => {
      state.countries = action.payload;
    },
    setCountriesOptions: (state, action: PayloadAction<Country[]>) => {
      state.countriesOptions = action.payload;
    },
    setManufacturers: (state, action: PayloadAction<string[]>) => {
      state.manufacturers = action.payload;
    },
    setManufacturersOptions: (state, action: PayloadAction<Manufacturer[]>) => {
      state.manufacturersOptions = action.payload;
    },
    setMinYear: (state, action: PayloadAction<number | null>) => {
      state.minYear = action.payload;
    },
    setMaxYear: (state, action: PayloadAction<number | null>) => {
      state.maxYear = action.payload;
    },
    incrementPage: (state) => {
      state.page += 1;
    },
    setProducts: (state, action: PayloadAction<Product[]>) => {
      state.products = action.payload;
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    decrementPage: (state) => {
      state.page -= 1;
    },
    setCount: (state, action) => {
      state.count = action.payload;
    },
    setLocale: (state, action: PayloadAction<ILocale>) => {
      state.locale = action.payload;
    },
    setOrderOptions: (
      state,
      action: PayloadAction<{ order: Iorder; order_by: Iorder_by }>
    ) => {
      state.order = action.payload.order;
      state.order_by = action.payload.order_by;
    },
    resetFilters: (_state, _action?: PayloadAction<undefined>) => {
      return {
        ...initialState,
      };
    },
    loadFiltersFromUrlParams: (state) => {
      const searchValue = getQueryStringValue('search');
      return {
        ...initialState,
        minPrice: getQueryStringValue('minPrice')
          ? parseInt(getQueryStringValue('minPrice') as string)
          : initialState.minPrice,
        maxPrice: getQueryStringValue('maxPrice')
          ? parseInt(getQueryStringValue('maxPrice') as string)
          : initialState.maxPrice,
        minYear: getQueryStringValue('minYear')
          ? parseInt(getQueryStringValue('minYear') as string)
          : initialState.minYear,
        maxYear: getQueryStringValue('maxYear')
          ? parseInt(getQueryStringValue('maxYear') as string)
          : initialState.maxYear,
        search: searchValue ? searchValue.toString() : initialState.search,
        page: getQueryStringValue('page')
          ? parseInt(getQueryStringValue('page') as string)
          : initialState.page,
        categories: getQueryStringValue('categories')
          ? (getQueryStringValue('categories') as string)
              .toLowerCase()
              .split(',')
          : initialState.selectedCategory,
        industry_categories: getQueryStringValue('industry_categories')
          ? (getQueryStringValue('industry_categories') as string)
              .toLowerCase()
              .split(',')
          : initialState.selectedCategory,
        manufacturers: getQueryStringValue('manufacturers')
          ? (getQueryStringValue('manufacturers') as string).split(',')
          : initialState.manufacturers,
        countries: getQueryStringValue('countries')
          ? (getQueryStringValue('countries') as string)
              .toUpperCase()
              .split(',')
          : initialState.countries,
        locale: state.locale,
      };
    },
  },
});

export const filters2SaveInUrl = [
  {
    key: 'page',
  },
  {
    key: 'minPrice',
  },
  {
    key: 'maxPrice',
  },
  {
    key: 'manufacturers',
    isArray: true,
  },
  {
    key: 'countries',
    isArray: true,
  },
  {
    key: 'order',
  },
  {
    key: 'order_by',
  },
  {
    key: 'search',
  },
];

export const {
  setPage,
  incrementPage,
  decrementPage,
  setSelectedCategory,
  setCategoriesOptions,
  setMinPrice,
  setMaxPrice,
  setCountries,
  setCountriesOptions,
  setManufacturers,
  setManufacturersOptions,
  setProducts,
  setSearch,
  setMinYear,
  setMaxYear,
  loadFiltersFromUrlParams,
  setIsLoading,
  setCount,
  setOrderOptions,
  setLocale,
  resetFilters,
} = productFilterSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectPage = (state: AppState) => state.productFilter.page;
export const products = (state: AppState) => state.productFilter.products;
export const countriesOptions = (state: AppState) =>
  state.productFilter.countriesOptions;
export const categoriesOptions = (state: AppState) =>
  state.productFilter.categoriesOptions;
export const industryCategoriesOptions = (state: AppState) =>
  state.productFilter.industryCategoriesOptions;

export default productFilterSlice.reducer;

// Save state on URL
export function setFormInUrl(form: ProductFilterState) {
  // select which product filters we should store on the URL
  const formKeys2SaveInUrl = filters2SaveInUrl.map((e) => e.key);
  let urlFormParamsFromForm = {} as any;
  Object.entries(form)
    .filter(([paramName]) => formKeys2SaveInUrl.includes(paramName))
    .map(([paramName, paramValue]) => {
      urlFormParamsFromForm[paramName] = isFilterValueIgnored(paramValue)
        ? paramValue
        : paramValue.toString();
    });
  // remove empty filters
  urlFormParamsFromForm = omitBy(urlFormParamsFromForm, (v) => {
    return isFilterValueIgnored(v);
  });
  // compare the current url query with urlFormParams
  const currentUrlParams = qs.parse(window.location.search);
  const urlParams = {
    ...currentUrlParams,
    ...urlFormParamsFromForm,
  };
  // if the url params are different from the current url params, update the url
  if (!isEqual(currentUrlParams, urlParams)) {
    console.log('not equal');
    console.log('urlFormParamsFromForm', urlFormParamsFromForm);
    // set a new url with the new filters (shallow is because we don't want to refresh the page)
    Router.push(
      {
        query: urlFormParamsFromForm,
        pathname: Router.asPath.split('?')[0],
      },
      undefined,
      {
        shallow: true,
      }
    );
  } else {
    console.log('equal');
  }
}
function isFilterValueIgnored(v: any): unknown {
  return v === '' || v === null || (Array.isArray(v) && v.length === 0);
}
